<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th sort-key="day">Day</vs-th>
        <vs-th sort-key="odd_even">Odd Even</vs-th>
        <vs-th sort-key="code">Customer Code</vs-th>
        <vs-th sort-key="name">Customer Name</vs-th>
        <vs-th sort-key="address">Address</vs-th>
        <vs-th sort-key="contact_name">Contact</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-button
              @click.stop="handleDelete(data[indextr].id)"
              size="small"
              color="danger"
              >Delete</vs-button
            >
          </vs-td>
          <vs-td> {{ data[indextr].day }}</vs-td>
          <vs-td> {{ data[indextr].odd_even }} </vs-td>
          <vs-td :data="data[indextr].customer_code">
            {{ data[indextr].customer_code }}
          </vs-td>
          <vs-td :data="data[indextr].customer_name">
            {{ data[indextr].customer_name }}
          </vs-td>
          <vs-td :data="data[indextr].address">
            {{ data[indextr].address ? data[indextr].address : ""
            }}{{
              data[indextr].sub_district
                ? ", " + data[indextr].sub_district
                : ""
            }}{{ data[indextr].district ? ", " + data[indextr].district : ""
            }}{{ data[indextr].city ? ", " + data[indextr].city : ""
            }}{{ data[indextr].province ? ", " + data[indextr].province : ""
            }}{{ data[indextr].country ? ", " + data[indextr].country : "" }}
            {{ data[indextr].postal_code ? data[indextr].postal_code : "" }}
          </vs-td>
          <vs-td :data="data[indextr].contact_name">
            {{ data[indextr].contact_name }}
            {{ data[indextr].contact_mobile }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    day: {
      type: String,
    },
    oddEven: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/datatable-plan", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            day: this.day,
            odd_even: this.oddEven,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/plan/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  watch: {
    detail() {
      this.getData();
    },
    day() {
      this.getData();
    },
    oddEven() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
